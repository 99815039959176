@import "global.scss";
a {
  text-decoration: none;
}

.header {
  height: 100vh;
  background: #DAECEA;
  .header-video {
    height: 100%;
    position: relative;
    text-align: center;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.5;
      z-index: 1;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center bottom;
    }
    .wallet-checker-link-container {
      position: absolute;
      bottom: 5%;
      right: 2%;
      border-radius: 5px;
      display: flex;
      background: #f4d020;
      padding: 5px 20px;
      align-items: center;
      height: 75px;
      box-shadow: 0 0 15px 0 #303030;

      img {
        height: 110px;
        margin-bottom: 35px;
        margin-left: -35px;
        width: auto;
      }
      .wallet-checker-link-text {
        padding-right: 70px;
        text-align: left;

        h2 {
          color: #b78d00;
          font-size: 0.9rem;
          font-weight: 600;
          margin: 0;
        }
        h1 {
          color: black;
          font-size: 1.4rem;
          font-weight: 600;
          margin: 0;
        }
      }

      .wallet-checker-link-button {
        background: black;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        color: white;
        font-size: 0.8rem;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background: #b89018;
        }
      }

    }

    .wallet-checker-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      background: #034cd2;
      padding: 40px 40px;
      align-items: center;
      box-shadow: 0 0 15px 0 #303030;

      input {
        color: black;
        background: white;
      }
      h2 {
        font-size: 2rem;
        font-weight: bold;
        text-shadow: 0 0 6px #a2a2a2;
        margin-bottom: 20px;
      }
      .wallet-checker-button {
        background: #f4d020;
        border: none;
        border-radius: 5px;
        margin-top: 20px;
        padding: 10px 50px;
        color: black;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background: #b89018;
        }
      }
      p {
        margin-top: 10px;
        margin-bottom: 0;
      }

    }
  }
}

.team {
  background-color: #ffd803;
  padding: 50px 0 70px 0;
}

.team-card {
  margin-bottom: 5px;
  margin-top: 15px;
  text-align: center;
  box-sizing: border-box;
  padding: 15px 15px 5px 15px;
  transition: width 2s, background-color 1s, box-shadow 1s;
  border-radius: 22px;
  border: 5px solid #373e98;
  p {
    transition: color 0.5s;
  }
}

.team-card:hover {
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(98, 153, 194, 1);
  p {
    color: white;
  }
  //background-color: rgba(255, 255, 255, 1);
  //background-color: #e3f6f5;
  //background-color: rgba(255, 255, 255, 1);
}

.team-wrapper {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

#top-team-name {
  font-size: 30px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 0;
  letter-spacing: 3px;
}

#team-name {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 0;
  letter-spacing: 3px;
}

#team-image {
  border-radius: 10px;
  width: 95%;
}

#top-member-image {
  margin: 10px;
  border-radius: 10px;
  width: 40%;
  float: left;
  height: 81%;
  @media screen and (max-width: 760px) {
    width: 99%;
  }
}

.top-member-team-card {
  padding-right: 25px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 760px) {
    padding: 10px;
  }
}

#team-description {
  text-align: justify;
  margin: 10px;
}

.team-member-link-block {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 10px;
}

#top-team-role {
  margin-bottom: 15px;
  margin-left: 10px;
  color: #2d2d2d;
}

#team-role {
  margin-bottom: 15px;
  margin-left: 10px;
  letter-spacing: 1px;
}

.top-member-link-block {
  display: inline-block;
  float: right;
}

.member-link {
  margin: 5px;
  width: 20px;
  color: #2d2d2d;
}

.team-title {
  text-align: center;
  font-size: 4em;
  font-weight: bold;
  color: #32325d;
  margin-bottom: 50px;
}

.roadmap {
  background: #272343;
  color: #e3f6f5;
  position: relative;
  padding-bottom: 4em;
  padding-top: 4em;
  .top-title {
    text-align: center;
    display: block;
    font-size: 4em;
    color: #62c4c7;
    margin: 0;
  }

  .roadmap-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 300px;
  }

  .card {
    border-radius: 36px;
    background: #e3f6f5;
    width: 100%;
    max-width: 500px;
    color: #1e1e24;
    font-size: 1.25rem;
    padding: 20px 10px 20px 20px;
    height: 75vh;
    height: calc(var(--ovh, 1vh) * 75);
    max-height: 600px;
    position: relative;
    flex: none;
    }

    p {
        font-size: 0.77em;
      }
  }

  .row {
    height: 100%;
  }

  #tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }

.slider {
  height: 520px;
  display: flex;
  flex-direction: column;
  place-content: center;
  transition: all 300ms ease 0s;

  div {
    display: flex;
    align-items: center;
    padding-left: 25px;
    height: 94px;
    flex: 0 0 94px;
    font-size: 2.25rem;
    text-align: center;
    background: #9003ff;
    border-radius: 50px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: bold;
  }

  .closest {
    opacity: 0.6;
    background: none;
  }
  .farthest {
    opacity: 0.3;
    background: none;
  }
  .hidden {
    visibility: hidden;
  }
}

.mobileRoadmap {
  display: none;
}

.slick-prev:before, .slick-next:before {
  display: none !important;
}

.mobileSlider {
  margin: 10px 0;
  .slick-list {
    margin: 0 10px;
  }
  .sliderItem {
    text-align: center;
    background: #9003ff;
    border-radius: 50px;
    cursor: pointer;
    height: 94px;
    width: fit-content !important;
    padding: 0 80px;
    margin: auto;
    display: flex !important;
    align-items: center;
    @media screen and (max-width: 450px) {
      padding: 0 45px;
    }
    p {
      font-weight: bold;
      font-size: 2.25rem;
      margin: auto;
      color: white;
      letter-spacing: 1px;
      @media screen and (max-width: 767px) {
      font-size: 1.25rem;
    }
    }
  }

  .slick-prev:before {
    font-size: 30px !important;
    color: #fff !important;
    background: transparent !important;
  }
  .slick-next:before {
    margin-left: -7px;
    font-size: 30px !important;
    color: #fff !important;
    background: transparent !important;
  }
}
.story {
  position: relative;
  background: #e3f6f5;
  .container {
    padding: 80px 80px;
    position: relative;
    z-index: 3;
  }
  .story-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    height: 200px;
  }
  .top-title {
    text-align: right;
    display: block;
    font-size: 4em;
    color: #62c4c7;
    margin: 0;
  }
  .bottom-title {
    text-align: right;
    display: block;
    font-size: 5em;
    font-weight: bold;
    margin-bottom: 30px;
    color: #272343;
  }
  p {
    font-size: 1.5em;
    text-align: justify;
  }
}

.story {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 400px;
  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single.proactive
    .slider-single-content {
    opacity: 0.5;
    transform: translateX(45%) scale(0.7);
  }
  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single.preactive
    .slider-single-content {
    opacity: 0.5;
    transform: translateX(-45%) scale(0.7);
  }

  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.value {
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
  position: relative;
  background: #ffd803;
  .alignCenter {
    align-items: center;
  }
  .caro {
    height: 400px;
  }
  .container {
    padding: 80px 0;
    position: relative;
    z-index: 3;
  }
  .top-title {
    text-align: right;
    display: block;
    font-size: 4em;
    color: #62c4c7;
    margin: 0;
  }
  .bottom-title {
    text-align: right;
    display: block;
    font-size: 5em;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p {
    font-size: 1.5em;
  }
  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.col-center {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 30px;
}

.what-is-ti-wrapper {
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  position: relative;
  background: #ffd803;
  .what-is-ti-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    //-webkit-transform: scaleX(-1);
    //transform: scaleX(-1);
    height: 300px;
  }
}
.what-is-ti {
  position: relative;

  .container {
    padding: 80px 50px;
    position: relative;
    z-index: 3;
  }

  .top-title {
    display: block;
    font-size: 4em;
    color: #62c4c7;
    margin: 0;
  }
  .bottom-title {
    display: block;
    font-size: 5em;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p {
    font-size: 1.5em;
  }
}

.wrapper {
  margin: 45px 30px 30px;
  display: flex;
  justify-content: space-evenly;
  max-width: 100vw;
  flex-wrap: wrap;
}

.cta {
  display: flex;
  padding: 5px 45px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
  background: #373e98;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
  align-items: center;
}

.cta:focus {
  outline: none;
}

.cta:hover {
  color: white;
  transition: 0.5s;
  box-shadow: 10px 10px 0 #fbc638;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

span {
  transform: skewX(15deg);
}

span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

.transition {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #201515;
  &:after {
    content: "";
    background: url(http://www.transparenttextures.com/patterns/dust.png) repeat
      center center;
    opacity: 0.07;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;

    z-index: 100;
    pointer-events: none;
  }
  .angle {
    width: 100%;
    height: 850px;
    margin-bottom: -400px;
    padding: 0;
    background-color: #201515;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    clip-path: polygon(50% 25%, 100% 0%, 100% 50%, 50% 75%, 0% 100%, 0% 50%);
    transition: all 0.7s ease-in-out;
    background-blend-mode: lighten;
    &.first {
      clip-path: polygon(50% 0%, 100% 0%, 100% 50%, 50% 75%, 0% 100%, 0% 0%);
    }
    &.last {
      clip-path: polygon(
        50% 25%,
        100% 0%,
        100% 100%,
        50% 100%,
        0% 100%,
        0% 50%
      );
    }
  }

  .one {
    background-image: url(../images/transitions/1.png);
  }
  .two {
    background-image: url(../images/transitions/2.png);
  }
  .three {
    background-image: url(../images/transitions/3.png);
  }
  .four {
    background-image: url(../images/transitions/4.png);
  }
  .five {
    background-image: url(../images/transitions/5.png);
  }
  .six {
    background-image: url(../images/transitions/6.png);
  }
}

.footer-section {
  background-color: #e3f6f5;
  position: relative;
  padding: 50px 0 50px 0;
  p {
    color: #222221;
    margin-top: 15px;
    display: inline-block;
  }
  .header-icons {
    color: #222221;
    cursor: pointer;
    height: 36px;
    margin: 5px;
    width: 36px;
  }
  .novem {
    color: white;
    font-weight: bold;
  }
}

.custom-shape-divider-top-0 {
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-0 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 70px;
  transform: rotate(180deg);
}

.custom-shape-divider-top-0 .shape-fill {
  fill: #e3f6f5;
}



/* SVG animations */

@keyframes color_anim {
  0% {
    fill: white;
  }
  50% {
    fill: #fbc638;
  }
  100% {
    fill: white;
  }
}

@media screen and (max-width: 600px) {
  .wallet-checker-link-container {
    border-radius: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;

    .wallet-checker-link-text {
      flex-grow: 1;
      padding-right: 0!important;

      h1 {
        font-size: 1rem;
      }

      h2 {
        font-size: 0.7rem;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .wallet-checker-link-container {
    img {
      display: none;
    }
  }
}

@media screen and (min-width: 992px) {
  .story,
  .roadmap,
  .what-is-ti {
    flex-direction: column;
    // min-height: 100vh;
    display: flex;
    .container {
      align-self: center;
    }
  }
}

@media (max-width: 900px) {
  .what-is-ti-bg {
    height: 180px !important;
  }
  .roadmap {
    padding: 30px 10px;
    h1 {
      text-align: center;
      font-size: 35px !important;
      color: #2f0c5e;
    }
  }
  .what-is-ti {
    .container {
      padding: 40px 35px;
      position: relative;
      z-index: 3;
    }

    .top-title {
      display: block;
      font-size: 2.2em;
      color: #62c4c7;
      margin: 0;
    }
    .bottom-title {
      display: block;
      font-size: 2.5em;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.5em;
    }

    .wrapper {
      margin: 60px 30px 30px;
      display: flex;
      justify-content: space-evenly;
    }

    .cta {
      display: flex;
      width: 100%;
      padding: 10px 20px;
      text-decoration: none;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      color: white;
      background: #373e98;
      transition: 1s;
      box-shadow: 6px 6px 0 black;
      transform: skewX(-15deg);
      margin-bottom: 20px;

      svg {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }

  .story {
    .container {
      padding: 60px 40px;
    }
    .story-bg {
      display: none;
    }
    .top-title {
      text-align: right;
      display: block;
      font-size: 2.5em;
      color: #62c4c7;
      margin: 0 0 10px 0;
    }
    .bottom-title {
      text-align: right;
      display: block;
      font-size: 2.5em;
      font-weight: bold;
      margin-bottom: 30px;
      color: #272343;
    }
    p {
      font-size: 1.5em;
      text-align: justify;
    }
  }

  .value {
    .caro {
      margin-top: 15px;
      height: 300px;
    }
    .container {
      padding: 20px;
      position: relative;
      z-index: 3;
    }
    .top-title {
      text-align: right;
      display: block;
      font-size: 2.5em;
      color: #62c4c7;
      margin: 0;
    }
    .bottom-title {
      text-align: right;
      display: block;
      font-size: 2.5em;
      font-weight: bold;
      margin-bottom: 30px;
    }
    p {
      font-size: 1.5em;
    }
    h1 {
      font-size: 2.5em;
      font-weight: bold;
    }
  }

  .team-title {
    font-size: 2.5em;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .desktopRoadmap {
    display: none;
  }
  .mobileRoadmap {
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
}

@import "lib/transition";
@import "lib/go_down";
