$primary-color: #fff;
$background-color: linear-gradient(54deg, #9164ff, #8bfff4);
$text-color: #3c3f64;

// STYLING
.timeline {
  ul {
    li {
      list-style-type: none;
      position: relative;
      width: 6px;
      margin: 0 auto;
      padding-top: 50px;
      background: #fff;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) rotate(45deg);
        width: 20px;
        height: 20px;
        z-index: 2;
        background: #eee;
      }
      div {
        position: relative;
        bottom: 35px;
        width: 400px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        img {
          height: 350px;
        }
      }
      &:nth-of-type(odd) {
        > div {
          left: -170px;
        }
      }
      &:nth-of-type(even) {
        > div {
          left: -170px;
        }
      }
    }
  }
}

// ANIMATION
.timeline {
  ul {
    li {
      div {
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease-in-out;
      }
      &:nth-of-type(odd) {
        div {
          transform: translate3d(200px, -100px, 0) rotate(10deg);
        }
      }
      &:nth-of-type(even) {
        div {
          transform: translate3d(-200px, -100px, 0) rotate(-10deg);
        }
      }
      &.in-view {
        div {
          transform: none;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .timeline {
    ul {
      li {
        div {
          width: 250px;
          flex-direction: column;
          div {
            width: 80%;
            margin: 10px;
          }
        }
        &:nth-of-type(even) {
          > div {
            left: -289px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  body {
    background: #8bfff4;
  }
  .timeline {
    ul {
      li {
        margin-left: 20px;
        div {
          width: calc(100vw - 91px);
        }
        &:nth-of-type(even) {
          > div {
            left: 45px;
          }
        }
      }
    }
  }
}
