// Global settings
@import url('https://fonts.googleapis.com/css?family=Poppins:900i');

$enable-caret:              true !default;
$enable-rounded:            true !default;
$enable-shadows:            true !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

@import "functions";
@import "~bootstrap/scss/functions";
@import "variables";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";

$primary-color: #90C3FA;
$secondary-color: #FED963;

@font-face {
  font-family:"Teen";
  src: url("../fonts/teen.ttf") format("truetype");
}

@font-face {
  font-family:"Teen";
  src: url("../fonts/teen_bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family:"Teen";
  src: url("../fonts/teen_light.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family:"Teen";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family:"Teen";
  src: url("../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 100;
}

html, body {
  background: #fffffe;
  color: #2d334a;
  font-family: 'Roboto', serif;
  overflow-y: overlay;
}

a {
  color: #222221;
}

a:hover {
  color: #222221;
}

h1 {
  color: #272343;
}

p {
  color: #2d334a;
  font-size: 1.2em;
}

.primary-bg {
  background: $primary-color;
}

.secondary-bg {
  background: $secondary-color;
}

.card {
  padding: 20px 20px;
  border-radius: 32px;
}

button:focus {
  outline: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}